<template>
  <div class="management">
    <b-row>
      <b-colxx xxs="12" class="mb-5">
        <div class="d-flex header align-items-center mb-2">
          <h1>Management</h1>
          <i
            v-if="currentUser && hasAdminRole"
            id="icon-settings"
            class="py-2 ml-auto simple-icon-settings mr-3"
            @click="onSettingsClick"
            v-tooltip="{
              content: 'Settings',
              placement: 'bottom',
              classes: ['light'],
              offset: 10
            }"
          ></i>
        </div>
        <nav-bar :tabs="orderTabs"></nav-bar>
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </b-colxx>
    </b-row>

    <b-modal
      id="settingsModal"
      ref="settingsModal"
      modal-class="modal-right"
      hide-footer
      title="Settings"
    >
      <div class="d-block">
        <h3>Data Reset</h3>
        <b-form @submit.prevent="onSettingsSubmit" class="av-tooltip tooltip-label-bottom">
          <b-row class="my-4">
            <b-col class="col-12 mb-2">
              <b-form-checkbox
                id="checkbox-user"
                name="checkbox-user"
                :value="true"
                :unchecked-value="false"
                v-model="deleteOptions.users"
              >
                Users Data
              </b-form-checkbox>
            </b-col>
            <b-col class="col-12 mb-2">
              <b-form-checkbox
                id="checkbox-sim"
                name="checkbox-sim"
                :value="true"
                :unchecked-value="false"
                v-model="deleteOptions.simulation"
                :disabled="checkboxSimDisabled"
              >
                Simulation Data
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div>
            <b-button size="lg" variant="danger" @click="onDeleteClick">Delete </b-button>
          </div>
          <p class="mt-2 font-weight-bold error-message" v-if="deleteInvalid">
            * Please select the data above
          </p>
        </b-form>
      </div>
    </b-modal>

    <!-- Confirm Action -->
    <action-confirm-modal
      actionButton="Delete"
      actionButtonVariant="danger"
      :showModal.sync="showConfirmModal"
      :processing="processing"
      @modalTrigger="onModalTrigger"
      @actionConfirmed="onDeleteSubmit"
      @actionCanceled="onActionCancel"
      class="delete-user-modal"
    >
      <template v-slot:body>
        <h6>Are you sure you want to delete the following data?</h6>
        <p>This action cannot be undone once confirmed.</p>
        <ul class="my-4">
          <li v-if="deleteOptions.users">Users Data</li>
          <li v-if="deleteOptions.simulation || deleteOptions.users">Simulation Data</li>
        </ul>
      </template>
    </action-confirm-modal>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { managementTabs } from '@/constants/menu'
import NavBar from '@/components/Common/NavBar.vue'
import ActionConfirmModal from '@/components/Modals/ActionConfirmModal.vue'
import { helperMixin } from '@/mixins/helperMixin'
export default {
  components: {
    NavBar,
    ActionConfirmModal
  },
  data() {
    return {
      deleteOptions: {
        users: false,
        simulation: false,
        claims: false,
        codes: false
      },
      checkboxSimDisabled: false,
      deleteInvalid: false,
      tabs: managementTabs,
      showEditModal: false,
      showNewModal: false,
      showConfirmModal: false,
      processing: false
    }
  },
  mixins: [helperMixin],
  methods: {
    ...mapActions(['resetUsersData', 'resetSimulationData']),
    onSettingsClick() {
      this.$refs['settingsModal'].show()
    },
    onDeleteClick() {
      let self = this
      if (
        self.deleteOptions.users ||
        self.deleteOptions.simulation ||
        self.deleteOptions.claims ||
        self.deleteOptions.codes
      ) {
        self.deleteInvalid = false
        self.onModalTrigger(true)
      } else {
        self.deleteInvalid = true
      }
    },
    async onDeleteSubmit() {
      let self = this
      self.processing = true
      if (self.deleteOptions.users) {
        await self.resetUsersData()
      } else if (self.deleteOptions.simulation) {
        await self.resetSimulationData()
      } else {
      }

      self.processing = false

      self.onModalTrigger(false)
      self.$refs['settingsModal'].hide()
    },
    async onModalTrigger(value) {
      this.showConfirmModal = value
    },
    onActionCancel() {
      this.onModalTrigger(false)
    }
  },
  computed: {
    orderTabs() {
      return _.orderBy(this.filteredTabs, ['order'])
    },
    filteredTabs() {
      let self = this
      const role = self.currentUser.role
      return self.tabs.filter((item) => item.role.includes(role))
    }
  },
  watch: {
    'deleteOptions.users': {
      handler(val) {
        if (val) {
          this.deleteOptions.simulation = true
          this.checkboxSimDisabled = true
        } else {
          this.deleteOptions.simulation = false
          this.checkboxSimDisabled = false
        }
      },
      deep: true
    }
  }
}
</script>
