<template>
  <ul class="nav nav-tabs-bottom separator-tabs ml-0 mb-5 text-uppercase">
    <li
      v-for="(tab, index) in tabs"
      :key="`tab-${index}`"
      :active="$route.path === tab.to"
      class="nav-item"
    >
      <router-link :to="tab.to" class="nav-link">{{ tab.label }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  }
}
</script>
