var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass:
        "nav nav-tabs-bottom separator-tabs ml-0 mb-5 text-uppercase",
    },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c(
        "li",
        {
          key: `tab-${index}`,
          staticClass: "nav-item",
          attrs: { active: _vm.$route.path === tab.to },
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: tab.to } },
            [_vm._v(_vm._s(tab.label))]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }