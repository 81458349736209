var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "management" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-colxx",
            { staticClass: "mb-5", attrs: { xxs: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex header align-items-center mb-2" },
                [
                  _c("h1", [_vm._v("Management")]),
                  _vm._v(" "),
                  _vm.currentUser && _vm.hasAdminRole
                    ? _c("i", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Settings",
                              placement: "bottom",
                              classes: ["light"],
                              offset: 10,
                            },
                            expression:
                              "{\n            content: 'Settings',\n            placement: 'bottom',\n            classes: ['light'],\n            offset: 10\n          }",
                          },
                        ],
                        staticClass: "py-2 ml-auto simple-icon-settings mr-3",
                        attrs: { id: "icon-settings" },
                        on: { click: _vm.onSettingsClick },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("nav-bar", { attrs: { tabs: _vm.orderTabs } }),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "settingsModal",
          attrs: {
            id: "settingsModal",
            "modal-class": "modal-right",
            "hide-footer": "",
            title: "Settings",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("h3", [_vm._v("Data Reset")]),
              _vm._v(" "),
              _c(
                "b-form",
                {
                  staticClass: "av-tooltip tooltip-label-bottom",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSettingsSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "my-4" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "col-12 mb-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-user",
                                name: "checkbox-user",
                                value: true,
                                "unchecked-value": false,
                              },
                              model: {
                                value: _vm.deleteOptions.users,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deleteOptions, "users", $$v)
                                },
                                expression: "deleteOptions.users",
                              },
                            },
                            [_vm._v("\n              Users Data\n            ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { staticClass: "col-12 mb-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                id: "checkbox-sim",
                                name: "checkbox-sim",
                                value: true,
                                "unchecked-value": false,
                                disabled: _vm.checkboxSimDisabled,
                              },
                              model: {
                                value: _vm.deleteOptions.simulation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deleteOptions, "simulation", $$v)
                                },
                                expression: "deleteOptions.simulation",
                              },
                            },
                            [
                              _vm._v(
                                "\n              Simulation Data\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "lg", variant: "danger" },
                          on: { click: _vm.onDeleteClick },
                        },
                        [_vm._v("Delete ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.deleteInvalid
                    ? _c(
                        "p",
                        { staticClass: "mt-2 font-weight-bold error-message" },
                        [
                          _vm._v(
                            "\n          * Please select the data above\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("action-confirm-modal", {
        staticClass: "delete-user-modal",
        attrs: {
          actionButton: "Delete",
          actionButtonVariant: "danger",
          showModal: _vm.showConfirmModal,
          processing: _vm.processing,
        },
        on: {
          "update:showModal": function ($event) {
            _vm.showConfirmModal = $event
          },
          "update:show-modal": function ($event) {
            _vm.showConfirmModal = $event
          },
          modalTrigger: _vm.onModalTrigger,
          actionConfirmed: _vm.onDeleteSubmit,
          actionCanceled: _vm.onActionCancel,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h6", [
                  _vm._v("Are you sure you want to delete the following data?"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v("This action cannot be undone once confirmed."),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "my-4" }, [
                  _vm.deleteOptions.users
                    ? _c("li", [_vm._v("Users Data")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deleteOptions.simulation || _vm.deleteOptions.users
                    ? _c("li", [_vm._v("Simulation Data")])
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }